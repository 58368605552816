<template>
	<div>
		<el-carousel height="12.5rem" indicator-position='none'>
			<el-carousel-item v-for="item in list" :key="item">
				<el-image :src="item" style="height:12.5rem ;width: 100%;"></el-image>
			</el-carousel-item>
		</el-carousel>
		<span v-if="tabs==1">
			<div style="height: 2.5rem"></div>
			<div class="Tips">
				欢迎您使用查询验证系统，请点击“确定”按钮获取本次查询结果
			</div>
			<div style="height: 1.875rem;"></div>
			<div class="text-align">
				<el-button type="success" @click="ScanCode">确定</el-button>
			</div>
		</span>
		<div>
			<div v-if="tabs==2">
				<div class="tips_div">
					<div class="titles">
						尊敬的用户您好：
					</div>
					<div v-if="res.code==0">
						<p>您所查询的数码为：{{res.data.barCode}}</p>
						<p>感谢您购买<span class="color_div">{{res.data.gysName}}</span>的官方正品。</p>
						<div v-if="res.data.barCode.substring(0,2)!='91'">
							<p class="title">产品编号：{{res.data.productCode}}</p>
							<p class="title">产品名称：{{res.data.productName}}</p>
							<p class="title">产品规格：{{res.data.productSpecs}}</p>
							<p class="title" v-if="res.data.productScph">生产批号：{{res.data.productScph}}</p>
						</div>
					</div>
					<div v-if="res.code==-1">
						<p>您所查询的数码为：{{res.data.barCode}}</p>
						<p class="color_div">{{res.msg}}</p>
						<p class="title">查询地点：{{res.data.qrAddress}}</p>
						<p class="title">查询时间：{{res.data.seachDate}}</p>
					</div>
					<div v-if="res.code==1">
						<p class="color_div">{{res.msg}}</p>
					</div>
				</div>
			</div>
		</div>
		<div class="con-bottom foot">
			
			<div class="">
				长按下方微信二维码，关注化氏官方微信
			</div>
			<el-image :src="require('@/assets/7.jpg')" style="width: 175px;height: 175px;"></el-image>
		</div>
		<div class="beian-footer">
			<a href="https://beian.miit.gov.cn" target="_blank">鲁ICP备15039581号-1</a>
		</div>
	</div>
	</div>
</template>

<script>
	import axios from 'axios'
	export default {
		name: 'index',
		data() {
			return {
				res: '',
				tabs: 1,
				list: [
					require('@/assets/1.jpg'),
					require('@/assets/2.jpg'),
					require('@/assets/3.jpg'),
					require('@/assets/4.jpg'),
					require('@/assets/5.jpg'),
					require('@/assets/6.jpg'),
				]
			}
		},
		created() {
			var code = this.$route.params.pathMatch;
			this.Barcode = code.split('/')[2];
			this.BarCode_Jy = code.split('/')[3];
			this.addrHand();
		},
		mounted() {},
		methods: {
			// 扫码验证
			ScanCode() {
				var that = this;
				var url = 'http://123.132.248.174:8099/api/wechat/barcode'
				axios.get(url, {
						params: {
							Barcode: that.Barcode,
							BarCode_Jy: that.BarCode_Jy,
							lat: that.lat,
							lng: that.lng,
						},
					})
					.then((res) => {
						that.tabs = 2
						that.res = res.data
						if (res.data.code == 0) {
							that.timer(url);
						}
					})
					.catch((error) => {
						console.log(error)
					})
			},
			// 定时器
			timer(e) {
				setTimeout(function() {
					window.location.href = 'http://www.hsx99.com/'
				},60000)
			},
			addrHand() {
				let that = this
				const loading = this.$loading({
					lock: true,
					text: '信息加载中',
					spinner: 'el-icon-loading',
					background: 'rgba(0, 0, 0, 0.7)'
				});
				let data = {
					key: "6CIBZ-PUVCI-RBQGS-5VAIO-FUHMH-DCBYC" //申请的密钥
				};
				let url = "https://apis.map.qq.com/ws/location/v1/ip"; //这个就是地理位置信息的接口
				data.output = "jsonp";
				this.$jsonp(url, data)
					.then(res => {
						loading.close();
						this.lat = res.result.location.lat;
						this.lng = res.result.location.lng;
					})
					.catch(error => {
						console.log(error);
					});
			},
		}
	}
</script>

<style>
	.beian-footer{
		position: fixed;
		bottom: 0;
		left: 0;
		right: 0;
		text-align: center;
	}
	.el-button--medium {
		padding: 10px 64px;
		font-size: 14px;
		border-radius: 4px;
	}

	.foot {
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		margin-top: 50px;
	}

	.Tips {
		margin: 0 auto;
		width: 200px;
		text-align: center;
		color: rgb(115, 115, 115);
	}

	.text-align {
		text-align: center;
	}

	.color_div {
		color: red;
		font-weight: bold;
	}

	.title {
		text-indent: 2em;
	}

	.titles {
		font-size: 15px;
		font-weight: bold;
		color: #595959;
		margin-bottom: 1.25rem;
	}

	.tips_div {
		padding: 20px;
		font-size: 15px;
		color: #333333;
		font-weight: 500;
	}
</style>
